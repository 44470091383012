th {
  @apply text-[18px] md:text-[13px] p-3 border-4 border-white bg-gray-100
}
td {
  @apply p-1;
}
td {
  @apply border-4 border-white  h-14 md:text-[10px] text-gray-600;
}
table {
  white-space: pre-wrap;
}
.title {
  background-image: url("../../images/bg-title.png");
}
.card-info {
  @apply flex flex-col gap-2;
}
.search-f {
  border-radius: 0px 8px 8px 0px;
  border-width: 2px 2px 2px 0px;
  outline: none;
}
.serach-i {
  border-radius: 8px 0px 0px 8px;
  border-width: 2px 0px 2px 2px;
}

.input1{
  outline: none;
  background-color: transparent;
  width: 100%;
  height: 100%;
  font-size: 14px;
  padding-right: 16px;
}

.label1 {
  position: absolute;
  right: 20px;
  top: 13px;
  transition: top 200ms ease-in, left 200ms ease-in, font-size 200ms  ease-in;
  font-size: 13px;
}
.input1:focus ~ .label1,
.input1:not(:placeholder-shown).input1:not(:focus) ~ .label1 {
  top: -1.5rem;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}