@tailwind base;
@tailwind components;
@tailwind utilities;

/* @font-face {
  font-family: "Vazir";
  src: local("Vazir"), url(../public/Fonts/Vazir-FD-WOL.ttf) format("ttf");
} */

/* @font-face {
  font-family: Vazir;
  src: url('../public/Fonts/vazir/');
  src: url('Vazir.eot?#iefix') format('embedded-opentype'),
       url('Vazir.woff2') format('woff2'),
       url('Vazir.woff') format('woff'),
       url('Vazir.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
} */





@font-face {
  font-family: Vazirmatn-FD;
  src: url("./fonts/Vazir-Bold-FD.eot");
  src: url("./fonts/Vazir-Bold-FD.eot?#iefix") format("embedded-opentype"),
    url("./fonts/Vazirmatn-FD-Bold.woff2") format("woff2"),
    url("./fonts/Vazir-Bold-FD.woff") format("woff"),
    url("./fonts/Vazir-Bold-FD.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: Vazirmatn-FD;
  src: url("./fonts/Vazir-Black-FD.eot");
  src: url("./fonts/Vazir-Black-FD.eot?#iefix") format("embedded-opentype"),
    url("./fonts/Vazirmatn-FD-Black.woff2") format("woff2"),
    url("./fonts/Vazir-Black-FD.woff") format("woff"),
    url("./fonts/Vazir-Black-FD.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: Vazirmatn-FD;
  src: url("./fonts/Vazir-Medium-FD.eot");
  src: url("./fonts/Vazir-Medium-FD.eot?#iefix") format("embedded-opentype"),
    url("./fonts/Vazirmatn-FD-Medium.woff2") format("woff2"),
    url("./fonts/Vazir-Medium-FD.woff") format("woff"),
    url("./fonts/Vazir-Medium-FD.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: Vazirmatn-FD;
  src: url("./fonts/Vazir-Light-FD.eot");
  src: url("./fonts/Vazir-Light-FD.eot?#iefix") format("embedded-opentype"),
    url("./fonts/Vazirmatn-FD-Light.woff2") format("woff2"),
    url("./fonts/Vazir-Light-FD.woff") format("woff"),
    url("./fonts/Vazir-Light-FD.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: Vazirmatn-FD;
  src: url("./fonts/Vazir-Thin-FD.eot");
  src: url("./fonts/Vazir-Thin-FD.eot?#iefix") format("embedded-opentype"),
    url("./fonts/Vazirmatn-FD-Thin.woff2") format("woff2"),
    url("./fonts/Vazir-Thin-FD.woff") format("woff"),
    url("./fonts/Vazir-Thin-FD.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

body {
  font-family: "Vazirmatn-FD", sans-serif;
  font-weight: 500;
  margin: 0;
  direction: rtl;
}

li {
  list-style: none;
}
