
.inputFild {
    display: flex;
    align-items: center;
    padding-left: 1px;
    background-color: #F7F7F7;
    padding-right: 3px;
    height: 50px;
    border-radius: 5px;
    gap: 4px;
    position: relative;
    top: 0;
    left: 0;
    width: 70%;
}

@media (max-width: 767px) {
    .inputFild{
        width: 90%;
        height: 50px;
    }
}

.input{
    outline: none;
    background-color: transparent;
    width: 100%;
    height: 100%;
    font-size: 16px;
}

.label{
    position: absolute;
    right: 34px;
    top: 14px;
    transition: top 200ms ease-in, left 200ms ease-in, font-size 200ms ease-in;
    font-size: 15px;
}


.input:focus ~ .label,
.input:not(:placeholder-shown).input:not(:focus) ~ .label {
    top: -1.5rem;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.5);
}
